import './index.scss';

const NotFound = () => {
  return (
    <div className="not-found-container">
      <h1 className="header-404">404</h1>
      <h2>Oops!</h2>
      <p className="paragraph-404">
        The page you are looking for does not exits!
      </p>
    </div>
  );
};

export default NotFound;
