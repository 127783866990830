import { createContext, useState, useEffect, useRef } from 'react';
import registrationFormValues from '../registrationInitialFormValues';

const RegisterFormContext = createContext({});

const { Provider } = RegisterFormContext;

const RegisterFormProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(
    Number(localStorage.getItem('currentStep')) || 1
  );
  const [profilePicture, setProfilePicture] = useState();

  const localSavedFormState = JSON.parse(
    String(localStorage.getItem('formData'))
  );

  const [formData, setFormData] = useState(
    localSavedFormState ?? registrationFormValues
  );

  if (!localStorage.getItem('currentStep')) {
    localStorage.setItem('currentStep', 1);
  }

  const handlePrevious = () => {
    if (currentStep) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleNext = () => {
    if (currentStep < 8) {
      setCurrentStep(currentStep + 1);
      localStorage.setItem('formData', JSON.stringify(formData));
    }
  };

  useEffect(() => {
    localStorage.setItem('currentStep', currentStep);
  }, [currentStep]);

  return (
    <Provider
      value={{
        formData,
        currentStep,
        setCurrentStep: (step) => setCurrentStep(step),
        setFormData: (_formData) => setFormData(_formData),
        handlePrevious: () => handlePrevious(),
        handleNext: () => handleNext(),
        setProfilePicture,
        profilePicture,
      }}
    >
      {children}
    </Provider>
  );
};

export { RegisterFormContext, RegisterFormProvider };
