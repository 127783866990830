import { CircularProgress, Box } from '@mui/material';

const Spinner = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        height: '100vh',
        width: '100%',
      }}
    >
      <CircularProgress size={50} />
    </Box>
  );
};

export default Spinner;
