import { lazy } from 'react';
import { RegisterFormProvider } from '../../containers/Register/RegistrationFormModel/FormContext/FormContext';

// Public pages

const ResetPassword = lazy(() =>
  import('../../containers/Login/ResetPassword')
);
const Register = lazy(() => import('../../containers/Register/Register'));

const FindMarketpros = lazy(() =>
  import('../../containers/FindMarketpros/FindMarketpros')
);
const Landing = lazy(() => import('../../containers/Landing/Landing'));
const NewPassword = lazy(() => import('../../containers/Login/NewPassword'));
const ResetSuccess = lazy(() => import('../../containers/Login/ResetSuccess'));
const QuestionnairePage = lazy(() =>
  import('../../containers/Questianairy/QuestionnairePage')
);
const Successful = lazy(() =>
  import('../../containers/Register/Success/Success')
);
const Login = lazy(() => import('../../containers/Login/Login'));
const LoginAdmin = lazy(() =>
  import('../../containers/AdminDashboard/AdminLogin')
);
const Vetting = lazy(() => import('../../containers/Vetting/Vetting'));
const RegisterAdmin = lazy(() =>
  import('../../containers/AdminDashboard/AdminRegister')
);
const Agent = lazy(() => import('../../containers/AgentPage/Agent'));

// Agent pages

const Dashboard = lazy(() => import('../../containers/Dashboard'));

// Admin pages

const AdminAgentPage = lazy(() =>
  import('../../containers/AdminDashboard/AdminAgentPage/AdminAgentPage')
);
const AdminAgentList = lazy(() =>
  import('../../containers/AdminDashboard/Dashboard/AdminAgentList')
);
const AdminReferencePersonPage = lazy(() =>
  import(
    '../../containers/AdminDashboard/AdminReferencePerson/AdminReferencePersonPage'
  )
);
const ApplicationDetails = lazy(() =>
  import(
    '../../containers/AdminDashboard/AdminAgentApplication/ApplicationDetails'
  )
);

const routes = [
  // Public Routes

  {
    name: 'Landing',
    path: '/',
    element: (props) => <Landing {...props} />,
    withNavigation: true,
    withNavBar: true,
    withFooter: true,
    roles: ['public'],
  },
  {
    name: 'Login',
    path: '/login',
    element: (props) => <Login {...props} />,
    roles: ['public'],
    withNavBar: true,
    withFooter: true,
  },

  {
    name: 'Register',
    path: '/register',
    element: (props) => (
      <RegisterFormProvider>
        <Register {...props} />
      </RegisterFormProvider>
    ),
    roles: ['public'],
    withNavBar: true,
    withFooter: true,
  },
  {
    name: 'Admin Register',
    path: '/admin-register',
    element: (props) => <RegisterAdmin {...props} />,
    roles: ['public'],
    withNavBar: true,
    withFooter: true,
  },
  {
    name: 'Reset Password',
    path: '/reset-password',
    element: (props) => <ResetPassword {...props} />,
    roles: ['public'],
    withNavBar: true,
    withFooter: true,
  },
  {
    name: 'New Password',
    path: '/new-password',
    element: (props) => <NewPassword {...props} />,
    roles: ['public'],
    withNavBar: true,
    withFooter: true,
  },
  {
    name: 'Recover Password',
    path: '/recoverpassword',
    element: (props) => <ResetSuccess {...props} />,
    roles: ['public'],
    withNavBar: true,
    withFooter: true,
  },
  {
    name: 'Payment Success',
    path: '/payment-success',
    element: (props) => <Successful {...props} />,
    roles: ['public'],
    withNavBar: true,
    withFooter: true,
  },
  {
    name: 'Vetting',
    path: '/vetting',
    element: (props) => <Vetting {...props} />,
    roles: ['public'],
    withNavBar: true,
    withFooter: true,
  },
  {
    name: 'Find Marketpros',
    path: '/find-marketpros',
    element: (props) => <FindMarketpros {...props} />,
    withNavigation: true,
    roles: ['public'],
    withNavBar: true,
    withFooter: true,
  },
  {
    name: 'Agent',
    path: '/agent/:id',
    element: (props) => <Agent {...props} />,
    withNavigation: true,
    roles: ['public'],
    withNavBar: true,
    withFooter: true,
  },
  {
    name: 'Questionnaire',
    path: '/Question/questionnaire',
    element: (props) => <QuestionnairePage {...props} />,
    roles: ['public'],
    withNavBar: true,
    withFooter: true,
  },

  {
    name: 'Admin Login',
    path: '/admin-login',
    element: (props) => <LoginAdmin {...props} />,
    roles: ['public'],
    withNavBar: true,
    withFooter: true,
  },

  // Agent Routes

  {
    name: 'Dashboard',
    path: '/dashboard',
    element: (props) => <Dashboard {...props} />,
    roles: ['agent'],
  },

  // Admin Routes

  {
    name: 'Admin Dashboard',
    path: '/admin-dashboard',
    element: (props) => <AdminAgentList {...props} />,
    roles: ['admin'],
  },
  {
    name: 'Single Admin Dashboard',
    path: '/admin-dashboard/:id',
    element: (props) => <AdminAgentPage {...props} />,
    roles: ['admin'],
  },
  {
    name: 'Application Detail',
    path: '/admin-dashboard/vetting/:vettingId/:id',
    element: (props) => <ApplicationDetails {...props} />,
    roles: ['admin'],
  },
  {
    name: 'Reference Page',
    path: '/admin-dashboard/reference-persons/:id/:vettingId/:agentId',
    element: (props) => <AdminReferencePersonPage {...props} />,
    roles: ['admin'],
  },
];

export default routes;
