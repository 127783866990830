import { Suspense, useContext } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { AuthContext } from '../shared/context/auth.context';
import Wrapper from '../shared/components/Wrapper';
import routes from '../shared/constants/routes';
import NotFound from './NotFound/NotFound';
import Spinner from '../shared/components/Spinner';

const PageRoute = ({
  component,
  withNavBar = false,
  withNavigation = false,
  withFooter = false,
  ...rest
}) => {
  return (
    <Wrapper
      withNavBar={withNavBar}
      withFooter={withFooter}
      withNavigation={withNavigation}
    >
      {component(rest)}
    </Wrapper>
  );
};

const AppRoutes = () => {
  const { isLoggedIn, isAdmin, isAgent } = useContext(AuthContext);

  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        {routes.map((page, index) => {
          if (page.roles.includes('public')) {
            return (
              <Route
                exact
                key={page.path}
                path={page.path}
                element={
                  <PageRoute
                    withNavigation={page?.withNavigation}
                    withNavBar={page?.withNavBar}
                    withFooter={page?.withFooter}
                    component={page.element}
                  />
                }
              />
            );
          }
          if (
            isLoggedIn &&
            ((isAdmin && page.roles.includes('admin')) ||
            (isAgent && page.roles.includes('agent')))
          ) {
            return (
              <Route
                exact
                key={page.path}
                path={page.path}
                element={
                  <PageRoute
                    withNavigation={page?.withNavigation}
                    withNavBar={page?.withNavBar}
                    withFooter={page?.withFooter}
                    component={page.element}
                  />
                }
              />
            );
          }
          if (!isLoggedIn && page.roles.includes('admin')) {
            return (
              <Route
                key={index}
                path={page.path}
                element={<Navigate to="/admin-login" replace />}
              />
            );
          }
          if (!isLoggedIn && page.roles.includes('agent')) {
            return (
              <Route
                key={index}
                path={page.path}
                element={<Navigate to="/login" replace />}
              />
            );
          }
          return null;
        })}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;