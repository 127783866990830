import { Button as MUiButton, withStyles } from '@material-ui/core';
import { CircularProgress } from '@mui/material';

const ButtonStyle = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 25,
    paddingBottom: 25,
    paddingLeft: 35,
    paddingRight: 35,
    background: '#AA8425',
    color: '#FFFFFF',
    borderRadius: 200,
    fontSize: 15,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    textTransform: 'none',
    height: 42,
    boxShadow: 'none',
    disabled: {
      backgroundColor: '#DCD9D6 !important',
      color: '#B0B9C0 !important',
    },
  },
};

const SpinnerStyle = {
  display: 'flex',
  padding: 15,
  justifyContent: 'center',
  alignItems: 'center',
};

const Button = withStyles({
  ...ButtonStyle,
})(({ loading = false, ...props }) => {
  if (loading)
    return (
      <div style={SpinnerStyle}>
        <CircularProgress size={25} />
      </div>
    );
  return <MUiButton {...props} disableRipple disableFocusRipple />;
});

export default Button;
