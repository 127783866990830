export const errors = [
  {
    name: 'UnknownError',
    statusCode: -1,
    comment: 'Something went wrong',
  },
  {
    name: 'Ok',
    statusCode: 0,
    comment: 'All is good',
  },
  {
    name: 'EmailNotFound',
    statusCode: 10,
    comment: 'Email was not found',
  },
  {
    name: 'PasswordNotMatching',
    statusCode: 11,
    comment: 'Password is incorrect',
  },
  {
    name: 'EmailAlreadyUsed',
    statusCode: 12,
    comment: 'Email already exists',
  },
  {
    name: 'VettingNotFound',
    statusCode: 13,
    comment: 'Vetting was not found',
  },
  {
    name: 'PhotoDoesNotSave',
    statusCode: 13,
    comment: 'The photo was not saved',
  },
  {
    name: 'VettingExist',
    statusCode: 14,
    comment: 'Vetting exists for current agent',
  },
  {
    name: 'ResetPasswordLinkExpired',
    statusCode: 15,
    comment: 'The reset password Link has been expired',
  },
  {
    name: 'QuestionnaireExist',
    statusCode: 16,
    comment: 'The questionnaire exists for this client',
  },
  {
    name: 'BadRequest',
    statusCode: 400,
    comment: 'Bad Request',
  },
  {
    name: 'Unauthorized',
    statusCode: 415,
    comment: 'Incorrect email or password',
  },
  {
    name: 'ServerError',
    statusCode: 500,
    comment: 'Internal Server Error',
  },
];
