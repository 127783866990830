import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "../../../../assets/images/icons/icon_logout.svg";
import { AuthContext } from "../../../../shared/context/auth.context";
import "./index.scss";

const Logout = () => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const onLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <div className="logout" onClick={onLogout}>
      <img src={LogoutIcon} alt="logout-icon" />
      <span>Logout</span>
    </div>
  );
};

export default Logout;
