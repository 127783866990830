const CONSTANTS = {
  USER: "user",
  TOKEN: "accessToken",
  REFRESH_TOKEN: "refreshToken",
  EXPIRES_AT: "expiresAt",
  publicUrls: {
    LOGIN: "/AuthAgents/Login",
  },
};

export default CONSTANTS;
