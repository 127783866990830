import {
  GET_QUESTIONS,
  SET_ERRORS,
  GET_AGENTS,
  GET_LANQUAGES,
  GET_SPECIALIZATIONS,
  GET_AREAS,
  GET_ADMIN_AGENTS,
  GET_AGENT,
  GET_AGENT_CONTACTS,
  GET_AGENT_PERSON_INFO,
  GET_AGENT_LANGUAGES,
  GET_AGENT_SPECIALITY,
  GET_AGENT_AREAS,
  GET_AGENT_QUALIFICATION,
  PUT_AGENT_PERSON_INFO,
  GET_AGENT_VETTING,
  GET_ANALYTICS_LEADTRENDS,
  GET_ANALYTICS_PROFILEVIEWS,
  GET_ANALYTICS_SEARCHRESULTS,
  GET_AGENT_SUBSCRIPTION_DATA,
  GET_SPECIALTY_QUESTIONS,
  GET_EMAIL_REGISTRATION_STATUS,
  GET_CURRENT_AGENT_APPLICATIONS,
} from './action-variable';

const initialState = {
  logged: false,
  errors: '',
  loading: false,
  questions: [],
  agents: [],
  agent: {},
  adminAgents: [],
  languages: [],
  specializations: [],
  contacts: [],
  personInfo: {},
  specialtyLanguage: [],
  areas: [],
  qualification: [],
  vetting: null,
  leadTrends: {},
  profileViews: {},
  searchResults: {},
  subscriptionData: {},
  specialtyQuestions: [],
  emailValid: false,
  agentApplications: []
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUESTIONS:
      return { ...state, questions: action.payload };
    case GET_SPECIALTY_QUESTIONS:
      return { ...state, specialtyQuestions: action.payload };
    case SET_ERRORS:
      return { ...state, errors: action.payload };
    case GET_ADMIN_AGENTS:
      return { ...state, adminAgents: action.payload };
    case GET_AGENTS:
      return { ...state, agents: action.payload };
    case GET_LANQUAGES:
      return { ...state, languages: action.payload };
    case GET_SPECIALIZATIONS:
      return { ...state, specializations: action.payload };
    case GET_AREAS:
      return { ...state, allAreas: action.payload };
    case GET_AGENT:
      return { ...state, agent: action.payload };

    case GET_AGENT_CONTACTS:
      return { ...state, contacts: action.payload };

    case GET_AGENT_PERSON_INFO:
      return { ...state, personInfo: action.payload };

    case GET_AGENT_SPECIALITY:
      return {
        ...state,
        specialtyLanguage: action?.payload?.map((el) =>
          el.answerBool !== null ? { ...el, specializationIds: [] } : el
        ),
      };

    case GET_AGENT_AREAS:
      return { ...state, areas: action.payload };

    case GET_AGENT_QUALIFICATION:
      return { ...state, qualification: action.payload };

    case PUT_AGENT_PERSON_INFO:
      return {
        ...state,
        personInfo: {
          ...action.payload,
          image: action.payload.image + '?t=' + Math.random(),
        },
      };

    case GET_AGENT_VETTING:
      return {
        ...state,
        vetting: action.payload,
      };

    case GET_ANALYTICS_LEADTRENDS:
      return {
        ...state,
        leadTrends: action.payload,
      };

    case GET_ANALYTICS_PROFILEVIEWS:
      return {
        ...state,
        profileViews: action.payload,
      };

    case GET_ANALYTICS_SEARCHRESULTS:
      return {
        ...state,
        searchResults: action.payload,
      };

    case GET_AGENT_SUBSCRIPTION_DATA:
      return {
        ...state,
        subscriptionData: action.payload,
      };
    case GET_EMAIL_REGISTRATION_STATUS:
      return {
        ...state,
        emailValid: action.payload,
      };
    case GET_CURRENT_AGENT_APPLICATIONS:
      return {
        ...state,
        agentApplications: action.payload
      };

    default:
      return state;
  }
};

export default appReducer;
