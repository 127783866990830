import React from "react";
import NavBar from "../../containers/Navbar/Navbar";
import Footer from "../../containers/Footer/Footer";

export default function Wrapper({
  withNavBar,
  withNavigation,
  withFooter,
  children,
}) {
  return (
    <>
      {withNavBar && <NavBar withNavigation={withNavigation} />}
      {children}
      {withFooter && <Footer />}
    </>
  );
}
