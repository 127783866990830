import axios from 'axios';
import CONSTANTS from './shared/constants';

const defaultOptions = {
  headers: {
    'Content-Type': 'application/json',
  },
};

// URLs that don't need authorization header
const publicUrls = [CONSTANTS.publicUrls.LOGIN];

const axiosInstance = () => {
  const instance = axios.create({
    defaultOptions,
    baseURL:
      process.env.REACT_APP_API ??
      'http://localhost:3001',
  });

  instance.interceptors.request.use(async (config) => {
    const token = localStorage.getItem('token');

    if (publicUrls.some((urlString) => config?.url?.includes(urlString))) {
      return config;
    }
    if (token) {
      config.headers.Authorization = token ? `Bearer ${token}` : '';
    }
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response) {
        if (error.response.status === 401) {
          //TODO: Add logout logic
          localStorage.removeItem('token');
        } else if (error.response.status > 500 && error.response.status < 600) {
          //TODO: add server is down page
          return;
        }
        return Promise.reject(error.response);
      } else if (error.request) {
        //TODO: add logic to handle request error
      } else {
        // Something happened in setting up the request that triggered an Error
        //TODO: add logic to handle error
      }
    }
  );

  return instance;
};

const instance = axiosInstance();

export default instance;
