import {
  GET_ADMIN_PERSONAL_INFO,
  SET_LOADING,
  SET_LOADING_SUCESS,
  GET_GENERAL_ADMIN_PERSONAL_INFO,
  GET_AGENT_APPLICATIONS,
  GET_AGENT_TRANSACTIONS,
  GET_AGENT_REFERENCES,
  GET_AGENT_APPLICATION,
  GET_REFERENCE_PERSON,
  GET_QUESTIANAIRY,
  GET_QUESTIANAIRY_WITH_DATA,
  GET_AGENT_DATA_QUESTIANAIRY,
  GET_SUBSCRIPTION_DATA
} from "./action-variable";

const initialState = {
  loading: true,
  adminPersonalInfo: {},
  adminAreasInfo: {},
  specialtyLanguageInfo: {},
  adminQualificationInfo: {},
  agentApplication: {},
  agentApplications: null,
  agentTransactions: null,
  agentReferences: null,
  questianairy: null
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMIN_PERSONAL_INFO:
      return { ...state, ...action.payload };
    case GET_GENERAL_ADMIN_PERSONAL_INFO:
      return { ...state, adminPersonalInfo: action.payload };
    case SET_LOADING:
      return { ...state, loading: true };
    case SET_LOADING_SUCESS:
      return { ...state, loading: false };
    case GET_AGENT_APPLICATIONS:
        return { ...state, agentApplications: action.payload };
    case GET_AGENT_TRANSACTIONS:
          return { ...state, agentTransactions: action.payload };
    case GET_AGENT_REFERENCES:
        return { ...state, agentReferences: action.payload };
    case GET_AGENT_APPLICATION:
        return { ...state, agentApplication: action.payload };
    case GET_REFERENCE_PERSON:
        return { ...state, referencePerson: action.payload };
    case GET_QUESTIANAIRY:
        return { ...state, questianairy: action.payload };
    case GET_QUESTIANAIRY_WITH_DATA:
        return { ...state, questianairyWithData: action.payload };
    case GET_AGENT_DATA_QUESTIANAIRY:
          return { ...state, agentDataQuestianairy: action.payload };
    case GET_SUBSCRIPTION_DATA:
            return { ...state, subscriptionData: action.payload };
   
    default:
      return state;
  }
};

export default adminReducer;
