import { createTheme } from '@material-ui/core';
import { createTheme as muiCreateTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      // BG colors
      light: '#FFFFFF',
      main: '#E5E5E5',
      dark: '#DCD9D6',
      background: '#F5F4F1',
    },
    secondary: {
      light: '#F5F4F1', // Card
      main: '#AA8425', // Gold
      dark: '#6C6B6B', // silver
      background: '#F1F0EB',
    },
  },
  Typography: {
    fontFamily: 'Inter, sans-serif',
    mainHeader: {
      fontFamily: 'Carlton Plain',
      fontSize: 90,
      color: '#2E2D31',
    },
    title: {
      fontFamily: 'Carlton Plain, sans-serif',
      fontSize: 70,
      color: '#2E2D31',
    },
    paragraphs: {
      fontFamily: 'Inter, sans-serif',
      fontSize: 18,
      color: '#2E2D31',
    },
    buttonText: {
      fontFamily: 'Outfit',
      fontSize: 18,
      color: '#FFFFFF',
    },
  },
});

export const muiTheme = muiCreateTheme({
  palette: {
    primary: {
      main: '#AA8425', // Gold
    },
  },
});
