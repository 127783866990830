import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './styles.scss';
import App from './App';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from '@mui/material/styles';
import { theme, muiTheme } from './theme';
import store from './store/index';
import './index.css';

const app = (
  <ThemeProvider theme={muiTheme}>
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </MuiThemeProvider>
  </ThemeProvider>
);

ReactDOM.render(app, document.getElementById('root'));
reportWebVitals();
