import jwtDecode from 'jwt-decode';
import { useEffect, createContext, useState } from 'react';
import { storage } from '../utils/storage';
import Spinner from '../components/Spinner';

const AuthContext = createContext(undefined);

const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState(null);
  const [loading, setLoading] = useState(true);

  const logout = () => {
    storage.clear();
    setAuthState(null);
  };

  const setToken = (token) => {
    if (token) {
      const decodedUser = jwtDecode(token);
      storage.setItem('token', token);

      setAuthState({
        ...decodedUser,
        token,
        isAdmin: decodedUser?.iss === 'admin',
        isAgent: decodedUser?.iss === 'agent',
      });
    }
  };

  const isAdmin = () => {
    return !!authState?.isAdmin;
  };

  const isAgent = () => {
    return !!authState?.isAgent;
  };

  useEffect(() => {
    const token = storage.getItem('token');
    if (token) {
      setToken(token);
    }
    setLoading(false); // add this line
  }, []);

  if (loading) {
    return <Spinner />; // or any other loading component you have
  }

  return (
    <Provider
      value={{
        authState,
        isAdmin: isAdmin(),
        isAgent: isAgent(),
        logout,
        setToken,
        isLoggedIn: !!authState,
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthProvider, AuthContext };
