export const registrationFormValues = {
  email: "",
  password: "",
  confirmPassword: "",
  firstName: "",
  lastName: "",
  bio: "",
  phone: "",
  licenceNumber: "",
  brokerageAssociation: "",
  licensePlace: "",
  brokerageAddress: "",
  area: {
    name: "",
    longitude: 0,
    latitude: 0,
  },
  specLangAnswers: [],
  qualifyAnswers: [],
  questionOneAnswers: null,
  questionTwoAnswer: null,
  file: "",
};

export default registrationFormValues;
