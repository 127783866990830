import { useContext } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import "./Navbar.scss";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Box from "@mui/material/Box";
import Logo from "../../assets/images/Marketpros.svg";
import ButtonGold from "../../shared/components/ButtonGold";
import { useNavigate } from "react-router-dom";
import Logout from "../Dashboard/components/logout";
import { AuthContext } from "../../shared/context/auth.context";

import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      maxWidth: "100%",
      boxShadow: "None",
      // fontFamily: theme.Typography.fontFamily,
      fontFamily: "Outfit",
      minHeight: "81px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    menuButton: {
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(10),
      marginLeft: theme.spacing(1),
    },
    menuWrapper: {
      display: "flex",
      flexDirection: "row",
      margin: "auto",
      height: "auto",
    },
    title: {
      textAlign: "center",
      fontSize: "15px",
      whiteSpace: "nowrap",
      fontFamily: "Outfit",
      fontWeight: "500",
      cursor: "pointer",
    },
    logo: {
      maxWidth: 500,
      marginRight: "0.7rem",
      cursor: "pointer",
    },
    registerButton: {
      margin: "0 3rem",
      marginRight: "4em",
    },
    loginAndRegister: {
      display: "flex",
      flexDirection: "row",
      overflow: "hidden",
    },
    loginButton: {
      textAlign: "right",
      marginLeft: "auto",
      textTransform: "none",
      marginRight: theme.spacing(1),
      fontFamily: "Outfit",
      fontWeight: "500",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        boxShadow: "none",
      },
      "&:active": {
        backgroundColor: theme.palette.primary.main,
        boxShadow: "none",
      },
    },
    carlton: {
      fontFamily: "Outfit",
    },
  })
);

export default function ButtonAppBar({ withNavigation }) {
  const { isLoggedIn, isAdmin } = useContext(AuthContext);
  const classes = useStyles();
  const navigate = useNavigate();

  const handleLogin = () => {
    if (isLoggedIn) {
      if (isAdmin) {
        return navigate("/admin-dashboard");
      }
      return navigate("/dashboard");
    }
    return navigate("/register");
  };

  const navigatetoPage = (page) => {
    switch (page) {
      case "Agents":
        navigate("/find-marketpros");
        break;
      case "Blogs":
        navigate("/find-marketpros");
        break;
      case "About Us":
        navigate("/find-marketpros");
        break;
      case "Become a MarketPros Agent":
        navigate("/register");
        break;
      default:
        navigate("/");
        break;
    }
  };

  const pages = [
    "Home",
    "Agents",
    // Future links
    // "Blogs",
    // "About Us",
    "Become a MarketPros Agent",
  ];
  const settings = ["Profile", "Account", "Dashboard", "Logout"];

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="sticky" className={`root`}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box className={`logo`} style={{ cursor: "pointer" }}>
            <img
              src={Logo}
              alt="MarketPros"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
              onClick={() => navigatetoPage()}
            />
          </Box>

          <Box
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
            className={`icon-bar`}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              className={`menu-appbar`}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page}
                  onClick={() => {
                    navigatetoPage(page);
                    handleCloseNavMenu();
                  }}
                >
                  <Typography textAlign="center" className={classes.logo}>
                    {page}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box
            className={`nav-item`}
            sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
          >
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => {
                  navigatetoPage(page);
                  handleCloseNavMenu();
                }}
                className={`nav-link`}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box className={`login-and-register`}>
            {!isLoggedIn ? (
              <Button
                color="inherit"
                className={classes.loginButton}
                onClick={() => {
                  navigate("/login");
                }}
              >
                Login
              </Button>
            ) : (
              <Logout />
            )}
            <ButtonGold
              className={`registration-btn`}
              onClick={handleLogin}
              sx={{
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
              }}
            >
              {!isLoggedIn ? "Become a MarketPros Agent" : "Dashboard"}
            </ButtonGold>
          </Box>

          {/* <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
