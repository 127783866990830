import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import appReducer from "./reducer";
import { reducer as toastrReducer } from "react-redux-toastr";
import { getErrors } from "./action";
import adminReducer from "./adminReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reducers = {
  appReducer: appReducer,
  toastr: toastrReducer,
  adminReducer: adminReducer,
};
const reducer = combineReducers(reducers);

const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));
store.dispatch(getErrors());

export default store;
